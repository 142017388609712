import React, { useState , useEffect } from "react";
import { Avatar, Dropdown } from "rsuite";
import "../staffing/styles/header.css";
import { useUser } from "../../context/UserContext";
import SidebarLeft from "../_asset/img/sidebar-left.svg";
import SearchNormal from "../_asset/img/search-normal.svg";
import NotificationIcon from "../_asset/img/notification.svg";

import { getNotifications } from "../../services/notificationService";
import Notifications from "./notifications"; 

import Pusher from 'pusher-js';
import toast from 'react-hot-toast' ; 
import { useProviderCompany } from "../../context/ProviderCompanyContext";
import { Link, useLocation, useParams } from "react-router-dom";

const Header = ({ isOpen, setIsOpen, breadcrumb }) => {

    const { userInfo } = useUser();
    const location = useLocation();
    const pathName = location?.pathname?.split('/')?.[3];
    // State to handle the visibility of the notifications dropdown
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(null);
    const providerCompanyId = useParams().providerCompanyId;
    const { providerCompanyData, fetchProviderCompanyData, providerManager } = useProviderCompany();

    // Toggle notification dropdown visibility
    const toggleNotifications = () => setShowNotifications(!showNotifications);
    let pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: 'eu'
    });

    useEffect(() => {
        if (providerCompanyId) {
          fetchProviderCompanyData(providerCompanyId);
          fetchNotifications();
        }
    }, [providerCompanyId, fetchProviderCompanyData]);

    const fetchNotifications = async () => {
        const resp = await getNotifications(providerCompanyId);
        setNotifications([...resp.data]);
        const unreadCount = notifications.filter((notif) => !notif.isRead).length;
        setUnreadCount(unreadCount);
        // setUnreadCount(countUnreadNotifications(resp.data));
    }


    /*
    useEffect(() => {
        if (providerManager) {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: "eu",
            });

            const clientChannel = pusher.subscribe(`provider-manager-${providerManager._id}`);
            console.log("clientChannel recevievd ", clientChannel);
            clientChannel.bind("notification", (data) => {
                console.log("data recevievd n pusher  ", data);
                const parsedMessage = JSON.parse(data.message);
                console.log("parsedMessage recevievd ", parsedMessage);
                setNotifications((notifs) => [parsedMessage, ...notifs]);
                setUnreadCount((n) => n + 1);

                toast.success('notification arrived', { position: "top-left" });

                switch (parsedMessage.success) {
                    case true:
                        toast.success(parsedMessage.message, { position: "top-right" });
                        break;
                    case false:
                        toast.error(parsedMessage.message, { position: "top-right" });
                        break;
                    default:
                        break;
                }
            });

            // Cleanup function to unbind and unsubscribe
            return () => {
                clientChannel.unbind("notification");
                pusher.unsubscribe(`provider-manager-${providerManager._id}`);
                pusher.disconnect();
            };
        }
    }, [providerManager]); 
    */

    useEffect(() => {
        if (!providerManager || !providerManager._id) {
            console.log("Pusher initialization skipped. Invalid providerManager.");
            return;
        }
    
        console.log("Initializing Pusher for providerManager:", providerManager._id);
    
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
            cluster: "eu",
        });
    
        const channelName = `provider-manager-${providerManager._id}`;
        const clientChannel = pusher.subscribe(channelName);
    
        console.log("Subscribed to channel:", channelName);
    
        clientChannel.bind("notification", (data) => {
            console.log("Received data from Pusher:", data);
            // this is only for testing 
            toast.success("Notification arrived", { position: "top-left" });


            // fix parsing error for data object below 
            // once the definition of object is finalized. 
    
            try {
                // Parse the received message
                const parsedMessage = JSON.parse(data.message);
                console.log("Parsed message received:", parsedMessage);
    
                // Update state
                setNotifications((notifs) => [parsedMessage, ...notifs]);
                setUnreadCount((n) => n + 1);

                // Handle parsed message success state
                switch (parsedMessage.success) {
                    case true:
                        toast.success(parsedMessage.message, { position: "top-right" });
                        break;
                    case false:
                        toast.error(parsedMessage.message, { position: "top-right" });
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.error("Error parsing notification message:", error);
            }
        });
    
        return () => {
            console.log("Cleaning up Pusher for channel:", channelName);
            clientChannel.unbind_all();
            pusher.unsubscribe(channelName);
            pusher.disconnect();
        };
    }, [providerManager]);
    
    


    
    return (
        <div className="header_wrapper ">
            <div className="leftside">
                <div className={`menubar ${isOpen ? 'active' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                    <img src={SidebarLeft} alt="" />
                </div>
                <span className="page_name" style={{ textTransform: 'capitalize' }}>
                    {breadcrumb ? breadcrumb : pathName}
                </span>
            </div>
            <div className="rightside">
                <div className="notification" onClick={toggleNotifications}>
                    <img src={NotificationIcon} alt="Notifications" />
                    { unreadCount > 0 && <span className="notification-icon">{unreadCount}</span>}
                    { showNotifications && (
                        <div className="notification-dropdown">
                            <Notifications
                                notifs={notifications}
                                setNotifs={setNotifications}
                                unreadCount={unreadCount}
                                setUnreadCount={setUnreadCount}
                            />
                        </div>
                    )}
                </div>
                
                <span className="line"></span>

                <Dropdown
                    title={
                        <div className="profile">
                            <Link to="/home" className="dashboard-sidebar-avatar-link">
                                <Avatar bordered circle src={userInfo.profilePictureUrl} />
                            </Link>
                            <div className="user_details">
                                <p className="name">{userInfo.firstName} {userInfo.lastName}</p>
                                <span className="roll">Dispatch Manager</span>
                            </div>
                        </div>
                    }
                >
                </Dropdown>
            </div>
        </div>
    );
};

export default Header;
