import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import WorkIcon from '@mui/icons-material/Work';
import { Card, CardContent, Typography, Grid, Divider, Button, Badge } from '@mui/material';
import { Link } from 'react-router-dom';
import { Drawer, Tabs, Toggle } from 'rsuite';
import Send from '../../../_asset/img/send-2.svg';
import { ReactComponent as Magicpen } from '../../../_asset/img/magicpen.svg';
import Paperclip from '../../../_asset/img/paperclip-2.png';
import AskAI from './tabs/askAI';
import CompanyDetails from "./tabs/details";
import CompanyDocuments from "./tabs/documents";

const CustomerDrawer = ({ companyData , providerCompanyId, 
    customerCompanies,
    filteredCustomerCompanies,
    setCustomerCompanies,
    setFilteredCustomerCompanies }) => {

    const [openDrawer, setOpenDrawer] = useState(false);

    return (
        <div>

            <td>
                <span className="share_icon" onClick={() => setOpenDrawer(true)}>
                    <img src={Send} alt="Send" />
                </span>
            </td>

            {/* Drawer Component */}
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} className='customer_side_drawer'>
                <Drawer.Body>
                    <div className='header_content'>
                        {/* <div className='profile'>
                            <img src={Rectangle} alt="Profile" />
                        </div> */}
                        <h2 className='drawer__title'>{companyData.companyFriendlyName }</h2>
                    </div>
                    <div className='body__content'>
                        <Tabs defaultActiveKey="1" appearance="subtle">
                            <Tabs.Tab eventKey="1" title="Ask AI">
                                <AskAI />
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="2" title="Details">
                                <div className='tabs__content' id='scrollBar'>
                                     <CompanyDetails initialCompanyData={companyData} providerCompanyId={providerCompanyId} 
                                      customerCompanies={ customerCompanies }
                                      filteredCustomerCompanies={filteredCustomerCompanies}
                                      setCustomerCompanies={setCustomerCompanies}
                                      setFilteredCustomerCompanies={setFilteredCustomerCompanies}
                                     />
                                </div>
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="3" title="Documents">
                                <div className='tabs__content' id='scrollBar'>
                                    {/* {
                                        companyData.serviceAgreementUrl && <a href={companyData.serviceAgreementUrl} target="_blank" rel="noopener noreferrer">Service Agreement</a>
                                    }
                                    { !companyData.serviceAgreementUrl && <p>No documents found.</p> } */}
                                    <CompanyDocuments companyData={companyData} providerCompanyId={providerCompanyId} 
                                     customerCompanies={ customerCompanies }
                                     filteredCustomerCompanies={filteredCustomerCompanies}
                                     setCustomerCompanies={setCustomerCompanies}
                                     setFilteredCustomerCompanies={setFilteredCustomerCompanies}
                                    />
                                </div>
                            </Tabs.Tab>
                        </Tabs>
                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
    );
};

// export default CustomerDrawer;

const NewOrder = ({customerCompanies, providerCompanyId,
    filteredCustomerCompanies,
    setCustomerCompanies,
    setFilteredCustomerCompanies
 }) => {

    const [loading, setLoading] = useState(true);
    const [isToggled, setIsToggled] = useState(false);
    const history = useHistory();
    const [openDrawer, setOpenDrawer] = useState(false);

    const handleCreateWorkOrder = (customerCompanyId) => {
        // history.push(`/provider-company/${providerCompanyId}/work-order/${customerCompanyId}/new`);
        history.push(`/provider-company/${providerCompanyId}/crm/${customerCompanyId}/job/new`);
    };

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };


    return (
        <div className="">
            <div className="staffing-set-up-guide-container">
                <div className="staffing-set-up-guide-card">
                    <div className="card-body p-0">
                        <div className="unassigne_content customer_lists">
                            <table class="table border-0">
                                <thead>
                                    <tr>
                                        <th>Customer</th>
                                        <th>Facility Type</th>
                                        {/* <th>Jobs</th> */}
                                        {/* <th>Website</th> */}
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody id="data-body">
                                    {
                                        filteredCustomerCompanies?.length > 0 ? filteredCustomerCompanies.map((company, index) => {
                                            return <tr key={index}>
                                                <td>
                                                    <p className="required_col">
                                                        <BusinessIcon style={{ fontSize: '20px', color: '#3D4784', verticalAlign: 'middle', marginRight: '8px' }} />
                                                    
                                                    {company.companyFriendlyName}</p>
                                                </td>
                                                <td>
                                                    <p className="required_col">{company.facilityType ? company.facilityType : 'N/A'}</p>
                                                </td>
                                                {/* <td>
                                                    <p className="required_col">{company.jobCount}</p>
                                                </td> */}
                                                {/* <td>
                                                    <p className="required_col">{company.companyWebsiteUrl? company.companyWebsiteUrl : 'N/A' }</p>
                                                </td> */}
                                                <td className="badge_tab">
                                                    <Badge onClick={() => handleCreateWorkOrder(company._id)}>
                                                         Create New Job
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <span className="share_icon" >
                                                        {/* <img src={Send} alt="" /> */}
                                                        <CustomerDrawer 
                                                         companyData={company} 
                                                         providerCompanyId={providerCompanyId }
                                                         customerCompanies={ customerCompanies }
                                                         filteredCustomerCompanies={filteredCustomerCompanies}
                                                         setCustomerCompanies={setCustomerCompanies}
                                                         setFilteredCustomerCompanies={setFilteredCustomerCompanies}
                                                        />
                                                    </span>

                                                </td>
                                            </tr>
                                        }) : null
                                    }
                                </tbody>
                            </table>
                            {
                                !filteredCustomerCompanies?.length > 0 && <div className='no_data'> <p className="provider-company-customer-no-companies p-0 m-0">No customer companies found.</p> </div>
                            }
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default NewOrder;
