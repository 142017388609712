import React, { useState, useEffect } from 'react';
import { Link, Redirect, useParams } from "react-router-dom";
import './styles/officer.css';
import '../../styles/dashboard.css';
import { Toggle, Checkbox } from 'rsuite';
import DashboardSidebar from "./dashboardSidebar"
import "../../styles/workOrder.css"
// import DetailMap from '../../agency/listing/listingdetail/detailmap' ;
import DistanceMap from './DistanceMap';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import toast from 'react-hot-toast';
import { getProviderOffers, postGeoCheckIn, postGeoCheckOut } from "../../../../services/staffingService";
// import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import StatusTabsHeader from "./workOrderStatusTabs"
import { ReactComponent as Calendar } from "../../../_asset/img/calendar.svg";
import { ReactComponent as ExternalLink} from "../../../_asset/img/external-link.svg";


import ShiftCalendar from "./agenda";
import ShiftAttendance from "./ShiftAttendance";
import OfficerDashboardSidebar from '../officerDashboardSidebar';
import OfficerHeader from '../officerHeader';

function formatJobDateTime(dateTime) {
  const date = new Date(dateTime);
  return date.toLocaleString('en-US', {
    weekday: 'short', // "Mon", "Tue", etc.
    year: 'numeric',  // "2024"
    month: 'short',   // "Jul"
    day: 'numeric',   // "31"
    hour: '2-digit',  // "10 PM"
    minute: '2-digit' // "30 PM"
  });
}


function createStreetViewLink(lat, lng) {
  return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`;
}

function formatTime(dateTime) {
  const date = new Date(dateTime);
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  });
}


const OrderCard = ({ offer, checkIn, staff, checkOut }) => {
  const { jobId: job } = offer;
  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="staff-order-card">
      <div className="staff-googlestreetview">
        <DistanceMap source={staff.geoLocation} destination={job.geoLocation} />
        <div className="staff-order-id">#{job._id}</div>
      </div>
      <div className="staff-order-content-wraper staff-order-details staff-accepte-details">
        <div className="staff-order-content">
          <div className="staff-order-header">
            {/* <h3 className='title'>Red Inc.</h3> */}
            <div className="staff-order-total">
              {job.service.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
            </div>
            <div className="staff-order-address">
              {job.formattedAddress}.
              <a href={createStreetViewLink(job.geoLocation.coordinates[1], job.geoLocation.coordinates[0])} target="_blank" rel="noreferrer">
                <ExternalLink />
              </a>
            </div>
            {/* <div className="staff-order-total">{job.customerName}</div> */}
          </div>
        </div>
        <ShiftAttendance offer={offer} checkIn={checkIn} checkOut={checkOut} />
       
      </div>
    </div>
  );
};

 {/* 
 <div className="staff-restaurant-info m-0">
          
    <div className="staff-restaurant check-out">
      <div className="staff-label"
      >Geo Check-Out</div>
    </div>

    <div className="staff-restaurant check-in">
      <div className="staff-label" 
      ><CheckIcon /> Geo Check-in</div>
    </div>

</div> 
*/}



const NewOrdersTab = ({ offers, checkIn, staff, checkOut, gridView }) => {
  return (
    <div className="staff-new-orders-tab ">
      {/* <div className="staff-new-orders-header d-flex justify-content-between">
          <div>New</div>
          <div> <span> Next >> </span> </div>  
      </div>  */}
      <div className={`staff-orders-container mt-2 ${gridView === 0 ? 'gridView' : 'listView'}`}>


        {offers.length >= 1 && offers.map(offer => (
          <OrderCard key={offer._id} offer={offer} staff={staff} checkIn={checkIn} checkOut={checkOut} />
        ))}

        {
          offers.length === 0 && <h6> No jobs are scheduled in the near future. </h6>
        }

      </div>
    </div>
  );
};



const StatusTabs = () => {

  const [openOffers, setOpenOffers] = useState([]);
  const [acceptedOffers, setAcceptedOffers] = useState([]);
  const [completedOffers, setCompletedOffers] = useState([]);
  const [staff, setStaff] = useState({});
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [gridView, setGridView] = useState(1);
  const { providerCompanyId } = useParams();

  useEffect(() => {
    getOffers();
  }
    , []);

  const getOffers = async () => {
    
    const resp = await getProviderOffers(providerCompanyId);
    // this we must get from the provider context
    // setOffers(resp.data.offer) ;
    setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open' }));
    setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted' }));
    setCompletedOffers(resp.data.offer.filter((o) => { return o.status === 'completed' }));
    setStaff({ ...resp.data.offer[0].provider_resource_id });

  }

  // if ( acceptedOffers.length === 0 ) return <Redirect to="/staffing/staff/dashboard/work-order/open" /> ; 

  const checkIn = async (offerId, shiftId) => {

    let geolocation = { lat: 0, lng: 0 };
    try {
      const resp = await postGeoCheckIn(providerCompanyId, shiftId, offerId, geolocation);
      setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open' }));
      setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted' }));
      setCompletedOffers(resp.data.offer.filter((o) => { return o.status === 'completed' || o.status === 'paid' }));
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
      }
    }
  }

  const checkOut = async (offerId, shiftId) => {

    let geolocation = { lat: 0, lng: 0 };

    try {
      const resp = await postGeoCheckOut(providerCompanyId, shiftId, offerId, geolocation);
      setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open' }));
      setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted' }));
      setCompletedOffers(resp.data.offer.filter((o) => o.status === 'completed' || o.status === 'paid'));
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
      }
    }
  }


  return (
    <>
      <div className="dashboard officer_dashboard accepeted_dashboard">
        <OfficerDashboardSidebar isOpen={showSideDrawer} />
        <div className='dashboard_content'>
          <OfficerHeader isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />

          <div className="staffing-new-assignments-body" id='scrollBar'>
            <div className='page_title'>
              <div className='left_side'>
                <h2>Offers</h2>
                <p>Manage shift offers</p>
              </div>
              <div className='right_side'>
                {/* <Link to={`/provider-company/${providerCompanyId}/crm`}>
                  <button className="create-new-job-button">
                    <Calendar /> Scheduled Shifts
                  </button>
                </Link> */}
              </div>
            </div>
            <div className="main-content">
              <div className="main-content-inner">
                {/* ===== status tabs ===== */}
                <StatusTabsHeader
                  providerCompanyId={providerCompanyId}
                  openOffers={openOffers}
                  acceptedOffers={acceptedOffers}
                  completedOffers={completedOffers}
                  activeTab={'accepted'}
                  setGridView={setGridView}
                  gridView={gridView}
                />
                {/* ====== new orders ==== */}
                {
                  Object.keys(staff).length > 0 && (
                    <NewOrdersTab offers={acceptedOffers} staff={staff} checkIn={checkIn} checkOut={checkOut} gridView={gridView} />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusTabs;
