import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import {
  // fetchCompanyConnectors,
  // fetchAllConnectorsMetadata,
  getConnectCard,
  createService
} from "../../../../../services/staffingService";

function ProviderConnectors({ providerCompanyConnectors, allAvailableConnectors , providerCompanyId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedConnectorId, setSelectedConnectorId] = useState(null);
  const [error, setError] = useState(null);

  

  const handleCreateService = async (connector) => {
    
    try {
      console.log('connector', connector)
      const response = await createService(providerCompanyId, connector.id);
      if (response?.data?.uri) {
        console.log("Redirecting to:", response.data.uri);
        window.location.href = response.data.uri;
      } else {
        console.error("No URI found in the response");
      }
    } catch (error) {
      console.error("Error in creating service:", error);
    }
  };
  


  const handleConnectorClick = async (connectorId) => {
    //setIsLoading(true);
    console.log("connectorId", connectorId);
    // const { url } = await getConnectCard(connectorId);   
    const response= await createService(providerCompanyId, connectorId)
    console.log("response", response);
    // window.location.href = `${url}&redirect_uri=http://localhost:3000/connectors/`;
    setSelectedConnectorId(connectorId);
  };

  const filteredConnectors = providerCompanyConnectors
    .filter((connector) =>
      allAvailableConnectors.some((service) => service.id === connector.service)
    )
    .map((connector) => {
      const service = allAvailableConnectors.find((s) => s.id === connector.service);
      return (
        <div
          key={connector.id}
          className="service-item"
          style={{
            display: "flex",
            alignItems: "center",
            margin: "10px",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
          onClick={() => handleConnectorClick(service.id)}
        >
          {service?.icon_url && (
            <img
              src={service.icon_url}
              alt={service.name}
              style={{ width: "40px", height: "40px", marginRight: "10px" }}
            />
          )}
          <h3 style={{ fontSize: "16px", margin: 0 }}>{connector.schema}</h3>
        </div>
      );
    });

  const allConnectorsDisplay = allAvailableConnectors.map((connector) => (
    <div
      key={connector.id}
      onClick={() => handleCreateService(connector)}
      // onClick={() => console.log("connector.id", connector.id)}
      className="service-item"
      style={{
        display: "flex",
        alignItems: "center",
        margin: "10px",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "5px",
      }}
    >
      <img
        src={connector.icon_url}
        alt={connector.name}
        style={{ width: "40px", height: "40px", marginRight: "10px" }}
      />
      <h3 style={{ fontSize: "16px", margin: 0 }}>{connector.name}</h3>
    </div>
  ));

  if (error) return <h2>An error occurred: {error}</h2>;
  if (isLoading) return <h5>Loading...</h5>;

  return (
    <div className="center-horizontal list-container">
      <div style={{ marginBottom: "20px" }}>
        <h2>Your Connectors:</h2>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {filteredConnectors.length > 0 ? (
            filteredConnectors
          ) : (
            <h6>No connectors available.</h6>
          )}
        </div>
      </div>
      <div>
        <h2>All Available Connectors:</h2>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          {allConnectorsDisplay}
        </div>
      </div>
    </div>
  );
}

export default ProviderConnectors;
