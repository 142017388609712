import React, { createContext, useContext, useState, useCallback } from 'react';
import { getProviderCompanyByIdContext } from '../services/staffingService';

const ProviderCompanyContext = createContext();

export const ProviderCompanyProvider = ({ children }) => {
    const [providerCompanyData, setProviderCompanyData] = useState(null);
    const [providerManager, setProviderManager] = useState(null);

    const fetchProviderCompanyData = useCallback(async (providerCompanyId) => {
        const response = await getProviderCompanyByIdContext(providerCompanyId);
        const data = response.data;
        setProviderCompanyData(data.providerCompany);
        setProviderManager(data.providerManager);
    }, []);

    return (
        <ProviderCompanyContext.Provider value={{ providerCompanyData, fetchProviderCompanyData , providerManager }}>
            {children}
        </ProviderCompanyContext.Provider>
    );
};

export const useProviderCompany = () => {
    return useContext(ProviderCompanyContext);
};


// does it make sense to get the provider company data 
// provider manager oles 
// in this context? 
