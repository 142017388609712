import axios from "axios";
import { toast } from "react-hot-toast";
import logger from "./logger";

axios.defaults.withCredentials = true;

let isRedirecting = false;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (error.response && error.response.status === 401 && !isRedirecting) {
      isRedirecting = true;
      setTimeout(() => {
        toast.error("Session Expired. Please Log in again.");
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
        isRedirecting = false;
      }, 100);
    } else if (error.response && error.response.status === 404) {
      window.location.href = "/404";
    } else if (!expectedError) {
      console.error("Unexpected error:", error);
      toast.error("An unexpected error occurred.");
    }

    return Promise.reject(error);
  }
);

/*
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (error.response && error.response.status === 401 && !isRedirecting) {
    isRedirecting = true;
    setTimeout(() => {
      toast.error("Session Expired. Please Log in again.");
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
      isRedirecting = false;
    }, 1);
  } else if (!expectedError) {
    logger.logException(error);
    toast.error("An unexpected error occurred.");
  }

  return Promise.reject(error);
});
*/

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

/* import axios from "axios";
import { toast } from "react-hot-toast";
import logger from "./logger";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(null, (error) => {
  const expectedError = 
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (error.response && error.response.status === 401) {
    setTimeout(() => {
      toast.error("Session Expired. Please Log in again.");
      window.location.href = "/login";
    }, 1);
  } else if (!expectedError) {
    logger.logException(error);
    toast.error("An unexpected error occurred.");
  }

  return Promise.reject(error);
});


export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete
};
*/
