import React, {useEffect , useState  } from 'react'; 
import '../../../../styles/listingitem.css' ; 
import { Link } from "react-router-dom"
import toast from 'react-hot-toast';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
// import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import Scheduler from "../../scheduler" ; 
import { InputNumber } from 'rsuite';
import { createOffer } from '../../../../../../services/staffingService';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ListingDetails = ({providerCompanyId , staffs, hoveredStaff , setStaffs , setHoveredStaff , job  }) => {

    
    const handleMouseEnter = (staffMember) => {
        setHoveredStaff(staffMember);
    };

    const [activeStaffId, setActiveStaffId] = useState(null);

    const openModal = (staffId) => {
        setActiveStaffId(staffId);
    };

    const closeModal = () => {
        setActiveStaffId(null);
    };

    const sendOffer = async (staff, job ) => {
        try {
            const resp = await createOffer(providerCompanyId, staff, job) ;
            const offerId = resp.data.offer._id ;
            setStaffs(prevStaffs => {
                const updatedStaffs = prevStaffs.map(staff => {
                    if (staff._id === resp.data.offer.provider_resource_id) {
                        return { ...staff, offerId: offerId };
                    }
                    return staff;
                });
                return updatedStaffs;
            });
            // setIsOpen(false);
            setActiveStaffId(null);

        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data);   
            }    
        }
    }

    const handleChange = (value, staffId) => {
        setStaffs(prevStaffs =>
            prevStaffs.map(staff =>
                staff._id === staffId ? { ...staff, offerPrice: value } : staff
            )
        );
    };
    

    return (
            <div className='listingDetails_section'>

                <div className="view_details_box mr-4">
                    { staffs.length && staffs.map((m) => (

                           <div key={m._id} className="view_box" onMouseEnter={() => handleMouseEnter(m)} >
                                <div className="title">
                                    {`${m.firstName} ${m.lastName}`}
                                    <div className="available_box daily_impre">
                                        {/* <span style={{fontSize : '12px'}}> 4 / 5 </span> */}
                                        <span style={{ fontSize: '12px', color: '#4A4A4A', fontWeight: 'bold', backgroundColor: '#F4F4F5', padding: '3px 6px', borderRadius: '4px', fontFamily: 'Arial, sans-serif' }}>
                                            {`${Math.round((job.hourlyPayRate - m.offerPrice) / job.hourlyPayRate * 100)}% Margin`}
                                        </span>
                                    </div>
                                </div>
                               
                                <div className="address "><img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675175047/free-tools/billboard-marker.svg" alt="" />
                                 {/* {`${m.street}, ${m.zipcode}, ${m.state} (${m.distanceMiles.toFixed(1)} miles away) `} */}
                                 {`${m.formattedLocation } (${m.distanceMiles?.toFixed(1)} miles away) `}
                                </div>
                                
                                <div className="daily_update">
                                    <div className="daily_impre">
                                        <img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675174406/free-tools/immpressions.svg" alt="" /> 
                                        {`20 jobs done in last 3 months`}
                                    </div>
                                    <div className="daily_approx">
                                        <img width="16px" src="https://res.cloudinary.com/zipscores-collection/image/upload/v1675174757/free-tools/prices.svg" alt="" /> 
                                        {`$${m.preferences.minHourlyRate} per hour `}
                                    </div>
                                </div>

                                <hr className='hr' />

                                {
                                  !m.offerId && 
                                    <div className="request_info"> 
                                        <div className="d-flex">
                                            <div className="info ">
                                                
                                                <span style={{ fontSize:'12.5px' , color:'#3e4784' , fontWeight: '400' }} > Set Hourly Rate </span> 

                                                <div className="ml-2" id="price-bidding"> 
                                                    <InputNumber 
                                                        size="xs" 
                                                        style={{ fontSize: '12.5px', fontWeight: '400' }} 
                                                        // placeholder={rate}
                                                        value={m.offerPrice}
                                                        onChange={(value) => handleChange(value, m._id)}
                                                    />
                                                </div>
                                                {/* <span style={{ color: '#3e4784', marginLeft: '5px', fontSize: '12.5px', fontWeight: '400'}}>$ per hour</span> */}
                                            </div>
                                        </div>
                                        {activeStaffId === m._id && (
                                            <Scheduler
                                                isOpen={true}
                                                onRequestClose={closeModal}
                                                className='scheduler-modal'
                                                overlayClassName='scheduler-modal-overlay'
                                                ariaHideApp={false}
                                                sendOffer={sendOffer}
                                                staff={m}
                                                job={job}
                                            />
                                        )}
                                        <div onClick={() => openModal(m._id)} className="info" > 
                                             Make Offer <ChevronRightOutlinedIcon />
                                        </div> 
                                    </div>
                                }
                                {
                                    m.offerId && 
                                    <div className="request_info"> 
                                        <div className="d-flex">
                                            <div className="info ">
                                                
                                                {/* <span style={{ fontSize:'12.5px' , color:'#3e4784' , fontWeight: '400' }} > Offer Sent </span>  */}

                                            </div>
                                        </div>

                                        {/* <Link to={`/staffing/agency/work-order/offer/${m.offerId}`} target="_blank">                                     */}
                                        <Link to={`/provider-company/${providerCompanyId}/jobs/${job._id}/offer/${m.offerId}`} target="_blank">                                    
                                            <div className="info" style={{color:'#28A745'}} > 
                                                Check Offer Progress <OpenInNewIcon style={{fontSize:'18px', marginLeft:'5px'}} />
                                            </div>
                                        </Link>
                                    </div>
                                }

                            </div>
                        ))
                    }
                </div>
            </div>
    )
}


export default ListingDetails; 