import React, { useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { addUserAsProviderManager, getTeamManagers } from '../../../../services/staffingService';
import { Redirect, Link, useParams } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import DashboardSidebar from "../../../common/dashboardSidebar";
import { useUser } from '../../../../context/UserContext';
import { useProviderCompany } from '../../../../context/ProviderCompanyContext';
import { Loader, Modal, SelectPicker } from 'rsuite';
import { AvatarGroup, Avatar, Badge, Button } from 'rsuite';
import { ReactComponent as MoreVertical } from '../../../_asset/img/more-vertical.svg';
import "./team.css";

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Header from '../../../common/header';

const providerManagerSchema = yup.object({
    providerManagerEmail: yup.string()
        .required('Email is required for Provider Manager')
        .email('Must be a valid email address')
});

const roles = [
    { label: 'Admin', value: 'admin' },
    { label: 'Manager Managing Managers', value: 'manager_managing_managers' },
    { label: 'Dispatch Manager', value: 'dispatch_manager' }
];

const Team = () => {
    const { userInfo, userInfoReady } = useUser();
    const { providerCompanyId } = useParams();
    const { providerCompanyData, fetchProviderCompanyData, providerManager } = useProviderCompany();
    const [loading, setLoading] = useState(true);
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const [managerResponse, setManagerResponse] = useState('');
    const [managerError, setManagerError] = useState(false);
    const [open, setOpen] = useState(false);
    const [providerManagers, setProviderManagers] = useState([]);
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState("");

    // State for role management
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [showSaveButtons, setShowSaveButtons] = useState([]);

    const fetchTeam = async () => {
        const resp = await getTeamManagers(providerCompanyId);
        setProviderManagers(resp.data.providerManagers);
    }

    const {
        register: registerManager,
        handleSubmit: handleSubmitManager,
        formState: { errors: errorsManager },
        reset: resetManager,
    } = useForm({
        resolver: yupResolver(providerManagerSchema),
    });

    const onSubmitProviderManager = async (data) => {
        try {
            const resp = await addUserAsProviderManager(providerCompanyId, data.providerManagerEmail);
            setManagerResponse(resp.data);
            setManagerError(false);
        } catch (error) {
            setManagerResponse(error.response.data || 'Failed to send invitation');
            setManagerError(true);
        }
    };

    useEffect(() => {
        if (providerCompanyId) {
            fetchProviderCompanyData(providerCompanyId);
            fetchTeam();
            setLoading(false);
        }
    }, [providerCompanyId, fetchProviderCompanyData]);

    useEffect(() => {
        setSelectedRoles(providerManagers.map(manager => manager.role ? manager.role._id : ''));
        setShowSaveButtons(providerManagers.map(() => false));
    }, [providerManagers]);

    const handleRoleChange = (index, value) => {
        const updatedRoles = [...selectedRoles];
        updatedRoles[index] = value;
        setSelectedRoles(updatedRoles);

        const updatedShowSave = [...showSaveButtons];
        updatedShowSave[index] = true;
        setShowSaveButtons(updatedShowSave);
    };

    const handleSave = (index) => {
        const manager = providerManagers[index];
        const newRole = selectedRoles[index];

        console.log(`Updating role for user ${manager.user_id._id} to ${newRole}`);

        const updatedShowSave = [...showSaveButtons];
        updatedShowSave[index] = false;
        setShowSaveButtons(updatedShowSave);
    };

    return (
        <div className="dashboard">
            <DashboardSidebar isOpen={showSideDrawer} />
            <div className='team_page'>
                <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />
                <div className="staffing-new-assignments-body">
                    <div className='page_title'>
                        <div className='left_side'>
                            <h2>Team</h2>
                            <p>Members of your team</p>
                        </div>
                        <div className='right_side'>
                            <button className='create__btn' onClick={() => setOpen(true)}>+ Invite</button>
                        </div>
                    </div>

                    <div className="staffing-set-up-guide-task">
                        {providerManagers.length >= 1 && providerManagers.map((manager, index) => (
                            <div className="team-provider-card" key={manager.user_id._id}>
                                <Avatar
                                    src={manager.user_id.profilePictureUrl}
                                    alt={`${manager.user_id.firstName} ${manager.user_id.lastName}`}
                                    className="team-avatar"
                                />
                                <div className="team-provider-details" style={{ flex: 1 }}>
                                    <div className="team-provider-name" style={{ fontWeight: 'bold' }}>
                                        {manager.user_id.firstName && manager.user_id.lastName 
                                            ? `${manager.user_id.firstName} ${manager.user_id.lastName}` 
                                            : manager.user_id.email}
                                    </div>
                                    
                                    <div className="team-provider-role" style={{ fontSize: '12px', color: '#888' }}>
                                        {manager.user_id.email}
                                    </div>

                                    {/* Show dropdown if role permissions include 'change_roles', otherwise display role name */}
                                    <div className="d-flex">

                                        {providerManager?.role.permissions.includes('change_roles') ? (
                                            <div className="team-provider-role" style={{ fontSize: '12px', color: '#555', marginTop: '5px' }}>
                                                Role: 
                                                <SelectPicker
                                                    data={roles}
                                                    value={selectedRoles[index]}
                                                    searchable={false}
                                                    onChange={(value) => handleRoleChange(index, value)}
                                                    placeholder="Select Role"
                                                    size="xs"
                                                    className="ml-2"
                                                    cleanable={false}
                                                    style={{ width: '200px', fontSize: '10px', color: '#555 !important'}}
                                                />
                                            </div>
                                        ) : (
                                            <div className="team-provider-role" style={{ fontSize: '12px', color: '#555', marginTop: '0px' }}>
                                                Role: {manager.role._id}
                                            </div>
                                        )}

                                    </div>

                                    {showSaveButtons[index] && (
                                        <Button
                                            onClick={() => handleSave(index)}
                                            size="xs"
                                            appearance="primary"
                                            style={{ marginTop: '5px' }}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </div>
                                
                                <div className="team-provider-status" style={{ marginLeft: '16px', textAlign: 'right' }}>
                                    <div className="team-options">
                                        <MoreVertical />
                                    </div> 
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Modal open={open} onClose={() => setOpen(false)} className='create_customer_modal_wrapper'>
                <Modal.Header>
                    <Modal.Title>Send Invite</Modal.Title>
                    <p>Add dispatch managers to this account</p>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitManager(onSubmitProviderManager)} className="form-group mt-3">
                        <div className="d-flex align-items-center mb-2">
                            <input
                                style={{ width: '250px', fontSize: '14px', padding: '10px', borderRadius: '8px', border: '1px solid #E4E4E4' }}
                                type="text"
                                className="form-control mr-2"
                                {...registerManager('providerManagerEmail')}
                                placeholder="Add manager email"
                            />
                            <button type="submit" className="btn staffing-button-form-add-user">+</button>
                        </div>
                        {managerResponse && (
                            <div className={`${managerError ? 'errorTextForm' : 'text-success'}`}>{managerResponse}</div>
                        )}
                        {errorsManager.providerManagerEmail && <div className="errorTextForm">{errorsManager.providerManagerEmail.message}</div>}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className='invite_as'>
                        <p>Invite as:</p>
                        <SelectPicker data={roles} searchable={false} placeholder={"Dispatch Member"} />
                    </div>
                </Modal.Footer>
            </Modal>

            {loading && <Loader backdrop center />}
        </div>
    );
};

export default Team;
