import React, { useState, useEffect } from "react";
import { Route , Switch } from "react-router-dom";
import { Toaster , toast } from "react-hot-toast"
// import Import from "./components/connectors/steps/import" ;
import ProtectedRoute from "./components/common/protectedRoute";
import './App.css';
// import "./components/_auth/auth.css"; 
// import { UserProvider } from './context/UserContext';

// import Home from "./components/homeLoggedIn/home";
import MainView from "./components/staffing/main"; 
import logger from "./services/logger";
import ReactGA from "react-ga4" ;
import NotFoundPage from "./components/common/notfound";
import { Helmet } from "react-helmet";

import OfficerBulk from "./components/staffing/agency/orm/import/main" ; 

// staffing 

import StaffAllocationCompanyNew from "./components/staffing/agency/staffAllocationCompanyNew" ; 
// import Assign from "./components/staffing/agency/jobs" ; 
import Assign from "./components/staffing/agency/jobs/dashboard/job" ; 
// import Integrations from "./components/staffing/agency/jobs/intergations" ; 
// import Statistics from "./components/staffing/agency/jobs/statistics" ; 
import OfficerRecommendations from "./components/staffing/agency/jobs/offers/recommendations/listing" ; 
import StaffDetail from "./components/staffing/agency/jobs/offers/recommendations/listingdetail/listingdetailpage" ; 
// import StaffDatabase from "./components/staffing/agency/jobs/staff-database/database" ;
import PartnerRegistration from "./components/staffing/agency/partnerRegistration" ;
import ProviderStaffAllocationCompanyOnboarding from "./components/staffing/agency/onboarding" ;
// import NewWorkOrderOld from "./components/staffing/agency/jobs/PreferenceCards/NewOrder" ; // delete later
import NewWorkOrder from "./components/staffing/agency/crm/jobform" ;
import NewCustomer from "./components/staffing/agency/crm/customerform" ;
import Customers from "./components/staffing/agency/crm/customer" ;
import OfferStatus from "./components/staffing/agency/jobs/offers/status/status" ;
import Offer from "./components/staffing/agency/jobs/offers/offer" ;
import Settings from "./components/staffing/agency/settings/setting" ;

import StaffAllocationAccount from "./components/staffing/agency/account/account" ;
import Signal from "./components/staffing/agency/signals/signal" ;
import Team from "./components/staffing/agency/team/team"
import Officer from "./components/staffing/agency/orm/officer"


import WorkOrder from "./components/staffing/officer/dashboard/workOrder"
import WorkOrderAccepted from "./components/staffing/officer/dashboard/workOrderAccepted"
import WorkOrderCompleted from "./components/staffing/officer/dashboard/workOrderCompleted"
import Agenda from "./components/staffing/officer/dashboard/agenda" ;
import ProviderResourceAccount from "./components/staffing/officer/account/account" ; 
import Performance from "./components/staffing/officer/dashboard/performance" ;
import SecurityStaffRegistration from "./components/staffing/officer/securityStaffRegistration" ;
import { getUserInfo } from  "./services/userService" ; 
import ResourceForm from "./components/staffing/officer/dashboard/resourceForm" ;

import PartnerSuccess from "./components/staffing/agency/partnerSuccess" ;

import 'rsuite/dist/rsuite.min.css' ; 
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    ReactGA.initialize('G-3KC33TK96R');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []); 

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRON === "production") {
      logger.init();
    }
  }, []);


  return (
    <>
        <Toaster position="top-center"  reverseOrder={false} /> 
        <Switch>
          {/* <ProtectedRoute path="/staffing/agency/jobs/:job/:staff" component={StaffDetail} />     */}


          <ProtectedRoute path="/provider-company/:providerCompanyId/jobs/:jobId/offer/:offerId" component={OfferStatus} />  
          <ProtectedRoute path="/provider-company/:providerCompanyId/jobs/:jobId/offer" component={Offer} />  
          <ProtectedRoute path="/provider-company/:providerCompanyId/jobs" component={Assign} />

          
          
          <ProtectedRoute path="/provider-company/:providerCompanyId/dashboard/account" component={StaffAllocationAccount} />
          <ProtectedRoute path="/provider-company/:providerCompanyId/team" component={Team} />


          {/* http://localhost:3000/provider-company/6728eabf3ee6a31c5c841d93/orm/import*/}

          <ProtectedRoute path="/provider-company/:providerCompanyId/orm/import" component={OfficerBulk} />
          <ProtectedRoute path="/provider-company/:providerCompanyId/orm" component={Officer} />


          <ProtectedRoute path="/provider-company/:providerCompanyId/settings" component={Settings} />

          <ProtectedRoute path="/provider-company/:providerCompanyId/onboarding" component={ProviderStaffAllocationCompanyOnboarding} />
          <ProtectedRoute path="/provider-company/new" component={StaffAllocationCompanyNew} />

          <ProtectedRoute path="/provider-company/:providerCompanyId/crm/:customerCompanyId/job/new" component={NewWorkOrder} />
          <ProtectedRoute path="/provider-company/:providerCompanyId/crm/new" component={NewCustomer} />
          <ProtectedRoute path="/provider-company/:providerCompanyId/crm" component={Customers} />
          {/* <ProtectedRoute path="/provider-company/:providerCompanyId/customer/new" component={NewCustomer} /> */}

          {/* new job create route change to nclude customers */}
          {/* <ProtectedRoute path="/provider-company/:providerCompanyId/work-order/:customerCompanyId/new" component={NewWorkOrder} /> */}

          <ProtectedRoute path="/provider-company/:providerCompanyId/signal" component={Signal} />

          
          {/* <ProtectedRoute path="/staffing/partner-registration" component={PartnerRegistration} />  */}


          <ProtectedRoute path="/provider-resource/:providerCompanyId/dashboard/work-order/open" component={WorkOrder} />
          <ProtectedRoute path="/provider-resource/:providerCompanyId/dashboard/work-order/accepted" component={WorkOrderAccepted} />
          <ProtectedRoute path="/provider-resource/:providerCompanyId/dashboard/work-order/completed" component={WorkOrderCompleted} />

          {/* <ProtectedRoute path="/staffing/staff/dashboard/agenda" component={Agenda} /> */}
          <ProtectedRoute path="/provider-resource/:providerCompanyId/dashboard/account" component={ProviderResourceAccount} />
          <ProtectedRoute path="/provider-resource/:providerCompanyId/dashboard/performance" component={Performance} />   
          <ProtectedRoute path="/provider-resource/:providerCompanyId/resource/details/add" component={ResourceForm} />

          <ProtectedRoute path="/staffing/security-staff/registration" component={SecurityStaffRegistration} />
          <ProtectedRoute path="/provider-company" component={MainView} />
           <ProtectedRoute path="/partner-success" component={PartnerSuccess} /> 

          <Route path="/security-staff/registration" component={SecurityStaffRegistration} />

          <Route path="/staffing/partner-registration" component={PartnerRegistration} />
          <ProtectedRoute path="/home" component={MainView} />

          <Route path="/404" component={NotFoundPage} />
          <Route path="/" component={PartnerRegistration} />
          {/* <Route path="/" component={MainView} /> */}
        </Switch>


    </>
  );
}

export default App;