import React, { useState, useEffect } from "react";
import ProviderConnectors from "./connectors";
import {
  fetchCompanyConnectors,
  fetchAllConnectorsMetadata,
} from "../../../../../services/staffingService";
import { Link, useLocation, useParams } from "react-router-dom";



export default function App() {
  const providerCompanyId = useParams().providerCompanyId;
  const [allAvailableConnectors, setAllAvailableConnectors] = useState([]);
  const [providerCompanyConnectors, setProviderCompanyConnectors] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const allConnectorsResponse = await fetchAllConnectorsMetadata();
        const companyConnectorsResponse = await fetchCompanyConnectors();

        setAllAvailableConnectors(allConnectorsResponse.data); // Metadata of all available connectors
        setProviderCompanyConnectors(companyConnectorsResponse.data); // Company-specific connectors
        setError(null);
      } catch (e) {
        console.error("Error fetching data:", e);
        setError(e.message);
      }
    };

    loadData();
  }, []);

  if (error) {
    return <h2>An error occurred: {error}</h2>;
  }

  return (
    <div>
      <ProviderConnectors
        allAvailableConnectors={allAvailableConnectors}
        providerCompanyConnectors={providerCompanyConnectors}
        providerCompanyId={providerCompanyId}
      />
    </div>
  );
}
