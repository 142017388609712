import http from "./http";
import apis  from "../config/apis.json";



const api = 
    process.env.REACT_APP_ENVIRON === "development"
    ? apis.dev
    : process.env.REACT_APP_ENVIRON === "production"
    ? apis.prod
    : apis.test;  


export function  getNotifications(providerCompanyId){
    return http.get(`${api}/notifications/provider/manager/${providerCompanyId}/all`)
}

export function markAsRead(){
    return http.put(`${api}/notification/read/mark`)
}