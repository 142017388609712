import React, { useState, useEffect } from 'react';
import './styles/officer.css';
import { Link, Redirect, useParams } from "react-router-dom";
import '../../styles/dashboard.css';
import { Toggle, Checkbox } from 'rsuite';
import "../../styles/workOrder.css"
import DistanceMap from './DistanceMap';
import RejectionModal from "./RejectionModal";

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StatusTabsHeader from "./workOrderStatusTabs"

import { getProviderOffers, getAcceptOffer } from "../../../../services/staffingService";
import toast from 'react-hot-toast';
import { ReactComponent as Calendar} from "../../../_asset/img/calendar.svg";
import { ReactComponent as ExternalLink} from "../../../_asset/img/external-link.svg";
import ShiftCalendar from "./agenda";
import OfficerDashboardSidebar from '../officerDashboardSidebar';
import OfficerHeader from '../officerHeader';


function formatJobDates(offer) {
  return offer.shifts.map(shift => {
    const date = new Date(shift.date);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  });
}

const OfferShiftsDisplay = ({ job, offer, openShiftCalendar }) => {

  const dates = formatJobDates(offer);
  const firstDate = dates[0];
  const additionalDatesCount = dates.length - 1;

  return (
    <div className="staff-order-name">
      <div>
        <span style={{ cursor: 'pointer' }} onClick={openShiftCalendar}>
          <span>
            {firstDate}
            {additionalDatesCount > 0 && (
              <span style={{ fontWeight: 'bold', margin: '5px' }}>
              (+ {additionalDatesCount} more shifts)
              </span>
            )}
            at ${offer.negotiations.providerHourlyRate} per hour
          </span>
        </span>
        <div
          className=""
          style={{ cursor: 'pointer', fontWeight: '500', color: 'rgb(219, 39, 119)', fontSize: '12px' }}
          onClick={openShiftCalendar}
        >
          View all Shifts
          {/* <OpenInNewIcon style={{ fontSize: '16px', marginLeft: '5px' }} /> */}
        </div>
      </div>
    </div>
  );
};

function createStreetViewLink(lat, lng) {
  return `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${lat},${lng}`;
}

const CountdownTicker = ({ when }) => {
  const offsetDays = 0;
  const targetTime = new Date(new Date(when).getTime() + offsetDays * 24 * 60 * 60 * 1000).getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetTime - now;

    const timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };

    return difference > 0 ? timeLeft : { days: 0, hours: 0, minutes: 0, seconds: 0 };
  };

  const [time, setTime] = useState(calculateTimeLeft());

  useEffect(() => {
    if (time.days === 0 && time.hours === 0 && time.minutes === 0 && time.seconds === 0) return;

    const timerId = setInterval(() => {
      setTime(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timerId);
  }, [time]);

  return (
    <div className="decision-time-note">
      Respond in
      <span style={{ color: '#c62828' }}>
        {time.days > 0 && ` ${time.days} days, `}
        {time.hours > 0 && ` ${time.hours} hrs, `}
        {` `}{time.minutes} mins, {time.seconds} secs
      </span> to avoid auto-rejection.
    </div>
  );
};


const OrderCard = ({ offer, offerAccept, staff, openOffers, setOpenOffers, acceptedOffers, setAcceptedOffers, providerCompanyId }) => {

  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);

  const openShiftCalendar = () => {
    setCalendarIsOpen(true);
  };

  const closeShiftCalendar = () => {
    setCalendarIsOpen(false);
  };

  const openRejectionModal = () => {
    setShowRejectionModal(true);
  };

  const closeRejectionModal = () => {
    setShowRejectionModal(false);
  };

  const { jobId: job } = offer;

  return (
    <div className="staff-order-card">
      <div className="staff-googlestreetview">
        <DistanceMap source={staff.geoLocation} destination={job.geoLocation} />
        <div className="staff-order-id">#{job._id}</div>
      </div>
      <div className="staff-order-content-wraper">
        <div className="staff-order-content">
          <div className="staff-order-header d-flex justify-content-between">
            <div>
              {/* <button onClick={openShiftCalendar}>Show Shifts</button> */}
              {/* <div className="staff-order-name">
                              {formatJobDateTime(job).split('\n').map((shift, index) => (
                                <div key={index}>{shift} at ${offer.negotiations.providerHourlyRate} per hour</div>
                              ))}
                          </div>

                          <div style={{cursor:'default', fontWeight:'400'}} onClick={openShiftCalendar} > 
                             Check Full Agenda <OpenInNewIcon style={{ fontSize: '16px', color:'blue' , marginBottom: '5px' }} />
                          </div> */}
              <OfferShiftsDisplay job={job} offer={offer} openShiftCalendar={openShiftCalendar} />
              {calendarIsOpen && (
                <ShiftCalendar
                  job={job}
                  offer={offer}
                  calendarIsOpen={calendarIsOpen}
                  closeShiftCalendar={closeShiftCalendar}
                  className='scheduler-modal'
                  overlayClassName='scheduler-modal-overlay'
                  ariaHideApp={false}
                  providerCompanyId={providerCompanyId}
                />
              )}
            </div>
          </div>
          <div className="staff-order-address">
            {job.formattedAddress}.{' '}
            <a href={createStreetViewLink(job.geoLocation.coordinates[1], job.geoLocation.coordinates[0])} target="_blank" rel="noreferrer">
              <ExternalLink />
            </a>
          </div>
          <div className="staff-order-total">
            {job.service.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          </div>
          {/* <Checkbox className="staff-order-total" style={{ marginLeft: '-10px', marginBottom: '-15px', marginTop: '-10px' }}>
                      Accept 5% less with the Early Payment Program. <Link>Terms & Conditions Apply.</Link>
                  </Checkbox> */}
        </div>
        <CountdownTicker when={offer.negotiations.expiredAt} />
          {/* <div className='terms_conditions'>
            <input type="checkbox" />
            <span>Accept 5% less with the Early Payment Program. <a href=":javascript()">Terms & Conditions Apply.</a></span>
          </div> */}
        <div className="staff-restaurant-info mt-3">
          <div className="staff-restaurant reject">
            <div className="staff-label" 
             onClick={openRejectionModal}
            ><ClearIcon /> Reject</div>
            {showRejectionModal && (
              <RejectionModal
                isOpen={true}
                onRequestClose={closeRejectionModal}
                className='scheduler-modal'
                overlayClassName='scheduler-modal-overlay'
                ariaHideApp={false}
                offerId={offer._id}
                openOffers={openOffers}
                setOpenOffers={setOpenOffers}
                acceptedOffers={acceptedOffers}
                setAcceptedOffers={setAcceptedOffers}
                providerCompanyId={providerCompanyId}

              />
            )}
          </div>
          <div className="staff-restaurant accept">
            <div className="staff-label" 
             onClick={() => { offerAccept(offer._id) }}
            ><CheckIcon /> Accept</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewOrdersTab = ({ offers, offerAccept, staff, openOffers, setOpenOffers, acceptedOffers, setAcceptedOffers, providerCompanyId, gridView }) => {
  return (
    <div className="staff-new-orders-tab">

      <div className={gridView === 0 ? 'gridView' : 'listView'}>

        {offers.length >= 1 && offers.map(offer => (
          <OrderCard key={offer._id} offer={offer} staff={staff} offerAccept={offerAccept}
            openOffers={openOffers}
            setOpenOffers={setOpenOffers}
            acceptedOffers={acceptedOffers}
            setAcceptedOffers={setAcceptedOffers}
            providerCompanyId={providerCompanyId}
          />
        ))}
        {
          offers.length === 0 && <h6> No new offers. </h6>
        }
      </div>
    </div>
  );
};

const StatusTabs = () => {

  const [openOffers, setOpenOffers] = useState([]);
  const [acceptedOffers, setAcceptedOffers] = useState([]);
  const [completedOffers, setCompletedOffers] = useState([]);
  const [staff, setStaff] = useState({});
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [gridView, setGridView] = useState(0);
  const { providerCompanyId } = useParams();

  useEffect(() => {
    getOffers();
  }
    , []);

  const getOffers = async () => {
    const resp = await getProviderOffers(providerCompanyId);

    setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open' }));
    setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted' }));
    setCompletedOffers(resp.data.offer.filter((o) => { return o.status === 'completed' }));
    if (resp.data.offer && resp.data.offer.length > 0) {
      setStaff({ ...resp.data.offer[0].provider_resource_id });
    }

  }

  const offerAccept = async (offerId) => {
    try {
      const resp = await getAcceptOffer(providerCompanyId, offerId);
      setOpenOffers(resp.data.offer.filter((o) => { return o.status === 'open' }));
      setAcceptedOffers(resp.data.offer.filter((o) => { return o.status === 'accepted' }));
      // redirect to this link here -  /staffing/staff/dashboard/work-order/accepted
      // return <Redirect to="/staffing/staff/dashboard/work-order/accepted" />;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
      }

    }
  }

  // if ( openOffers.length === 0 ) return <Redirect to="/staffing/staff/dashboard/work-order/accepted" /> ;

  return (
    <>

      <div className="dashboard officer_dashboard">
        <OfficerDashboardSidebar isOpen={showSideDrawer} />
        <div className='dashboard_content'>
          <OfficerHeader isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />

          <div className="staffing-new-assignments-body" id='scrollBar'>
            <div className='page_title'>
              <div className='left_side'>
                <h2>Offers</h2>
                <p>Manage shift offers</p>
              </div>
              {/* <div className='right_side'>
                <Link to={`/provider-company/${providerCompanyId}/crm`}>
                  <button className="create-new-job-button">
                    <Calendar /> Scheduled Shifts
                  </button>
                </Link>
              </div> */}
            </div>
            <div className="main-content">
              <div className=" ">
                {/* ===== status tabs ===== */}
                <StatusTabsHeader
                  providerCompanyId={providerCompanyId}
                  openOffers={openOffers}
                  acceptedOffers={acceptedOffers}
                  completedOffers={completedOffers}
                  activeTab={'open'}
                  setGridView={setGridView}
                  gridView={gridView}
                  />
                {/* ====== new orders ==== */}
                {
                  Object.keys(staff).length > 0 && (
                    <NewOrdersTab
                      offers={openOffers}
                      staff={staff}
                      offerAccept={offerAccept}
                      openOffers={openOffers}
                      setOpenOffers={setOpenOffers}
                      acceptedOffers={acceptedOffers}
                      setAcceptedOffers={setAcceptedOffers}
                      providerCompanyId={providerCompanyId}
                      gridView={gridView}

                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default StatusTabs;
