import React from "react" ; 
import '../../../../styles/unassignedListView.css';
import { Badge } from "@mui/material";
import { useEffect, useState } from "react";
import SideDrawer from "./sideDrawer";
import Send from '../../../../../_asset/img/send-2.svg';
import JobStreetView from "../../offers/status/jobStreetView";
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import { Progress } from 'rsuite';

const UnassignedListView = ({ data , getDeadlineText }) => {
    const [open, setOpen] = useState(false);
    const [getByItem, setGetByItem] = useState(null);
    const [selectedJob, setSelectedJob] = useState(null); 


    useEffect(() => {
        if(!open){
            setGetByItem(null);
        }
    }, [open]);

    function getFacilityIconURL(facilityType) {
        const baseURL = "https://res.cloudinary.com/zipscores-collection/image/upload/v1728421483/icons/";
        
        // List of available facility types and corresponding icon names
        const facilityIcons = {
          workplace: "workplace.png",
          warehouse: "warehouse.png",
          office: "office.png",
          factory: "factory.png",
          construction: "construction.png"
        };
      
        // Convert the keys of facilityIcons to an array
        const availableTypes = Object.keys(facilityIcons);
      
        // If the facilityType exists, use it; otherwise, choose a random icon
        const selectedIcon = facilityIcons[facilityType] 
          ? facilityIcons[facilityType] 
          : facilityIcons[availableTypes[Math.floor(Math.random() * availableTypes.length)]];
      
        // Return the full URL
        return `${baseURL}${selectedIcon}`;
    }



    return (
        <div className="unassigne_content">
            <table className="table border-0">
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Fulfillment Deadline</th>
                        <th>Shift Coverage </th>
                        <th>Active Offers</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody id="data-body">
                    {data.length > 0 &&
                        data.map((job, index) => {
                            const deadlineText = getDeadlineText(job?.shifts);
                            const percent = job?.statistics.overallCoverage;
                            const color = percent === 100 ? '#52c41a' : '#3385ff';

                            return (
                                <React.Fragment key={job._id || index}>
                                    <tr>
                                        <td>
                                            <div className="user_details">
                                                <div className="profile">
                                                    <img src={getFacilityIconURL(job.customer_company_id?.facilityType)} alt="" />
                                                </div>
                                                <div className="details">
                                                    <h4 className="name">{job.customer_company_id.companyFriendlyName}</h4>
                                                    <p className="address">{job.formattedAddress}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p className="required_col"> {deadlineText} </p>
                                        </td>
                                        <td>
                                            <p className="rate_col" style={{ width: 40 }}>
                                                <Progress.Circle percent={percent.toFixed(0)} strokeColor={color} />
                                            </p>
                                        </td>
                                        <td>
                                            <p className="rate_col">{job.statistics.validOffersCount}</p>
                                        </td>
                                        <td>
                                            <span
                                                className="share_icon"
                                                onClick={() => {
                                                    setOpen(true);
                                                    setSelectedJob(job);
                                                }}
                                            >
                                                <img src={Send} alt="" />
                                            </span>
                                        </td>
                                    </tr>

                                    {/* Render the SideDrawer only for the selected job */}
                                    {selectedJob && selectedJob._id === job._id && open && (
                                        <SideDrawer
                                            job={job}
                                            isOpen={open}
                                            setIsOpen={(state) => {
                                                setOpen(state);
                                                if (!state) setSelectedJob(null); // Close the drawer and reset selected job
                                            }}
                                            getByItem={selectedJob}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                </tbody>
            </table>
        </div>
    )
};

export default UnassignedListView;