import http from "./http";
import apis  from "../config/apis.json";



const api = 
    process.env.REACT_APP_ENVIRON === "development"
    ? apis.dev
    : process.env.REACT_APP_ENVIRON === "production"
    ? apis.prod
    : apis.test;  

    

// http://localhost:3001/staffing/nylas/auth
export function nylasOAuth(){
    return http.get(`${api}/staffing/nylas/oauth`);
}


// http://localhost:3001/staffing/nylas/calendar/delete?grantId=undefined

export function unlinkCalendar(grantId){
    return http.delete(`${api}/staffing/nylas/calendar/delete/${grantId}`);
}

export function getStaffUser(){
    return http.get(`${api}/staffing/user`);
}

export function zendeskOAuth(){
    return http.get(`${api}/staffing/zendesk/oauth`);
}

export function unlinkZendesk(){
    return http.delete(`${api}/staffing/zendesk/unlink`);
}

export function getZendeskTickets(){
    return http.get(`${api}/staffing/zendesk/tickets/`);
}

//http://localhost:3001/staffing/security/security

export function getSecurityStaffsAll(){
    return http.get(`${api}/staffing/security/staffs/all`);
}

/*
export function getSecurityStaffsOrderId(orderId){
    return http.get(`${api}/staffing/security/staffs/${orderId}`);
}

export function createFinalOffer(offerId, finalRatePercentage, finalAmount){
    return http.post(`${api}/staffing/offer/final/create`, {
        offerId, finalRatePercentage, finalAmount
    });
}

*/



//=================================== // 
// ============== provider manager 
//=================================== // 

// ===============  provider-manager company create & onboarding 

export function createStaffAllocationCompany(companyData) {
    return http.post(`${api}/staffing/provider/manager/company/create`, {
        companyName: companyData.businessName,      // Company name (business name)
        supportEmail: companyData.supportEmail,     // Support email
        supportPhone: companyData.supportPhone,     // Support phone
        vatId: companyData.vatId,                   // VAT/Tax ID
        businessAddress: companyData.businessAddress, // Business address
        location: companyData.location || {}        // Location details (latitude, longitude, etc.)
    });
}


export function getUserProviderManagers(){
    return http.post(`${api}/staffing/provider/manager/companies`, {});
}

export function getUserProviderResources(){
    return http.post(`${api}/staffing/provider/manager/resources`, {});
}


export function createStripeAccountLink(providerCompanyId){
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/stripe/link/create`);
}


export function addUserAsProviderManager(providerCompanyId , email){
    return http.post(`${api}/staffing/provider/manager/${providerCompanyId}/add/manager`, {providerCompanyId: providerCompanyId , newUser : email });
}

export function inviteUserAsProviderResource(providerCompanyId , email){
    return http.post(`${api}/staffing/provider/manager/${providerCompanyId}/add/resource`, {providerCompanyId: providerCompanyId , newUser : email });
}


export function getProviderManagerAndResource(providerCompanyId){
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/manager-and-resource`);
}

// deprecate this later 
export function getProviderManagerAndResourceDetails(providerCompanyId){
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/manager-and-resource/details`);
}

export function getTeamManagers(providerCompanyId){
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/team/members/details`);
}

export function getTeamResources(providerCompanyId){
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/team/resources/details`);
}


export function getProviderCompanyByIdContext(providerCompanyId){
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}`);
}

export function updateProviderResourcePreferences(providerCompanyId, providerResourceId, preferencesData) {

    
    return http.put(`${api}/staffing/provider/manager/${providerCompanyId}/${providerResourceId}/preferences`, {
        minHourlyRate: preferencesData.minHourlyRate,
        maxDistanceInMiles: preferencesData.maxDistanceInMiles,
        shiftDays: preferencesData.shiftDays,
        shiftTimes: preferencesData.shiftTimes,
        servicesOffered: preferencesData.servicesOffered,
    });
}


export function updateProviderResourceDetails(providerCompanyId, providerResourceId, selectedLocation , detailsData) {
    return http.put(`${api}/staffing/provider/manager/${providerCompanyId}/${providerResourceId}/details`, {
        ...selectedLocation,
        ...detailsData
        /*
        address: detailsData.address,
        phoneNumber: detailsData.phoneNumber,
        dateOfBirth: detailsData.dateOfBirth,
        emergencyContactName: detailsData.emergencyContactName,
        emergencyContactPhone: detailsData.emergencyContactPhone,
        */
    });
}


export function addFileToProviderResourceByTeam(providerCompanyId, providerResourceId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return http.put(`${api}/staffing/provider/manager/${providerCompanyId}/${providerResourceId}/add-file`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function removeFileFromProviderResourceByTeam(providerCompanyId, providerResourceId, fileId) {
    return http.put(`${api}/staffing/provider/manager/${providerCompanyId}/${providerResourceId}/remove-file`, { fileId });
}




// ===================== provider-manager settings 


export function getProviderManagerSSOSettings(providerCompanyId){
    return http.get(`${api}/staffing/provider/manager/settings/sso/${providerCompanyId}`);
}

export function updateProviderLegalInfo(providerCompanyId, legalData) {
    return http.put(`${api}/staffing/provider/manager/settings/legal/${providerCompanyId}`, {
        name: legalData.name,
        address: legalData.address,
        vatTaxId: legalData.vatTaxId,
        supportEmail: legalData.supportEmail,
        supportPhone: legalData.supportPhone,
        location: legalData.location,
    });
}

export function updateProviderRecommendationPreferences(providerCompanyId, recommendationData) {
    return http.put(`${api}/staffing/provider/manager/settings/preferences/recommendations/${providerCompanyId}`, {
        showOfficersWithinMiles: recommendationData.showOfficersWithinMiles,
        highestMargin: recommendationData.highestMargin,
        withSameCustomer: recommendationData.withSameCustomer,
        goodReviews: recommendationData.goodReviews
    });
}

export function updateProviderTimeKeepingPreferences(providerCompanyId, timeKeepingData) {
    return http.put(`${api}/staffing/provider/manager/settings/preferences/timekeeping/${providerCompanyId}`, {
        checkInStartsAtMinutesBefore: timeKeepingData.checkInStartsAtMinutesBefore,
        checkInEndsAtMinutesAfter: timeKeepingData.checkInEndsAtMinutesAfter,
        minimumShiftLengthHours: timeKeepingData.minimumShiftLengthHours,
        checkoutValidUntilMinutesAfter: timeKeepingData.checkoutValidUntilMinutesAfter 
    });
}



export function updateProviderOffersPreferences(providerCompanyId, offersData) {
    return http.put(`${api}/staffing/provider/manager/settings/preferences/offers/${providerCompanyId}`, {
        officerResponseTimeHours: offersData.officerResponseTimeHours
    });
}




// ===================== provider-manager jobs and customer company 

export function createNewJob(job, providerCompanyId , customerCompanyId){
    return http.post(`${api}/staffing/provider/manager/${providerCompanyId}/job/${customerCompanyId}/create`, {job, providerCompanyId});
}

/*
export function createCustomerCompany(providerCompanyId, companyDetails) {
    return http.post(`${api}/staffing/provider/manager/${providerCompanyId}/customer-company/create`, companyDetails);
}
*/

export function createCustomerCompany(providerCompanyId, formData) {
    return http.post(`${api}/staffing/provider/manager/${providerCompanyId}/customer-company/create`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function updateCustomerCompanyDetails(providerCompanyId, customerCompanyId, companyData) {
    return http.put(`${api}/staffing/provider/manager/${providerCompanyId}/customer-company/${customerCompanyId}/details`, {
      companyLegalName: companyData.companyLegalName,
      companyFriendlyName: companyData.companyFriendlyName,
      hourlyBillingRate: companyData.hourlyBillingRate,
      companyWebsiteUrl: companyData.companyWebsiteUrl,
      facilityType: companyData.facilityType,
      contactPersonName: companyData.contactPersonName,
      phone: companyData.phone,
      email: companyData.email,
    });
}

export function addFileToCustomerCompany(providerCompanyId, customerCompanyId, file, fileType) {
    const formData = new FormData();
    formData.append('documentFile', file); // Append the actual file
    formData.append('fileType', fileType); // Optional file type (e.g., SLA, layoutPlan)

    return http.put(
        `${api}/staffing/provider/manager/${providerCompanyId}/customer-company/${customerCompanyId}/add-new-file`, 
        formData, 
        { 
            headers: { 
                'Content-Type': 'multipart/form-data' 
            } 
        }
    );
}


export function removeFileFromCustomerCompany(providerCompanyId, customerCompanyId, fileId) {
    return http.put(
        `${api}/staffing/provider/manager/${providerCompanyId}/customer-company/${customerCompanyId}/remove-file`, 
        { fileId } 
    );
}


export function getCustomerCompany(providerCompanyId, customerCompanyId) {
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/customer-company/${customerCompanyId}`);
}

export function getCustomerCompanies(providerCompanyId) {
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/customer-companies`);
}

export function getJobs(providerCompanyId){
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/jobs`);
}

export function getJobMetadata(providerCompanyId, jobId) {
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/job/metadata/${jobId}`);
}

export function getRecommendation(providerCompanyId, jobId){  //===== recommendation engine 
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/job/recommendations/${jobId}`);
}

export function getShiftPlan(providerCompanyId, jobId){
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/job/shift-plan/${jobId}`);
}

export function postJobComment(providerCompanyId, jobId, comment) {
    return http.post(`${api}/staffing/provider/manager/${providerCompanyId}/job/${jobId}/comment`, { comment });
}

export function getCommentsForJob(providerCompanyId, jobId) {
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/job/${jobId}/comments`);
}

export function assignJob(providerCompanyId, jobId, assignedProviderManagerId) {
    return http.put(`${api}/staffing/provider/manager/${providerCompanyId}/job/${jobId}/assign`, { assignedProviderManagerId });
}


// deprecate 
export function createCustomerCheckoutSession(providerCompanyId, jobId) {
    return http.post(`${api}/staffing/provider/company/${providerCompanyId}/job/customer-checkout-session`, { jobId: jobId });
}




// ===================== provider-manager offers 

export function createOffer(providerCompanyId, staff, job){
    return http.post(`${api}/staffing/provider/manager/${providerCompanyId}/offer/create`, {providerResource: staff, job: job});
}

export function getOffer(providerCompanyId, offerId){
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/offer/${offerId}`);
}



export function createRevisedOffer(providerCompanyId, offerId, revisedProviderHourlyRate){
    return http.post(`${api}/staffing/provider/manager/${providerCompanyId}/offer/revised/create`, {
        offerId, revisedProviderHourlyRate
    });
}


// ====== provider manager bulk imports 

export function fetchAllConnectorsMetadata(){
    return http.get(`${api}/staffing/provider/manager/connectors/metadata`);
}

export function fetchCompanyConnectors(){
    return http.get(`${api}/staffing/provider/manager/connectors`);
}

export function getConnectCard(connectorId) {
    return http.get(`${api}/staffing/provider/manager/connectors/${connectorId}/form`);
}


// http://localhost:3001/provider/manager/6728eabf3ee6a31c5c841d93/connectors/7shifts/create
export function createService(providerCompanyId, service) {
    return http.get(`${api}/staffing/provider/manager/${providerCompanyId}/connectors/${service}/create`);
}

//========================================================= // 
// ============== provider resource 
//========================================================= // 


// ============== provider resource company and onboarding ===== //

export function getProviderResourceDetails(providerCompanyId){
    return http.get(`${api}/staffing/provider/resource/${providerCompanyId}/details`);
}

export function updateResourceDetails(providerCompanyId, resourceDetails){
    return http.put(`${api}/staffing/provider/resource/${providerCompanyId}/details/update`, {providerCompanyId, resourceDetails});
}

export function createStripeAccountProviderResource(providerCompanyId) {
    return http.post(`${api}/staffing/provider/resource/${providerCompanyId}/stripe/account/create`);
}

export function completeStripeOnboardingProviderResource(providerCompanyId) {
    return http.post(`${api}/staffing/provider/resource/${providerCompanyId}/stripe/link/create`);
}

// ============== provider resource details, preferences, documents  ===== //


export function updateProviderResourcePreferencesByOfficer(providerCompanyId, providerResourceId, preferencesData) {
    return http.put(`${api}/staffing/provider/resource/${providerCompanyId}/${providerResourceId}/preferences`, {
        minHourlyRate: preferencesData.minHourlyRate,
        maxDistanceInMiles: preferencesData.maxDistanceInMiles,
        shiftDays: preferencesData.shiftDays,
        shiftTimes: preferencesData.shiftTimes,
        servicesOffered: preferencesData.servicesOffered,
    });
}


export function updateProviderResourceDetailsByOfficer(providerCompanyId, providerResourceId, selectedLocation , detailsData) {
    return http.put(`${api}/staffing/provider/resource/${providerCompanyId}/${providerResourceId}/details`, {
        ...selectedLocation,
        ...detailsData
    });
}



export function addFileToProviderResourceByOfficer(providerCompanyId, providerResourceId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return http.put(`${api}/staffing/provider/resource/${providerCompanyId}/${providerResourceId}/add-file`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function removeFileFromProviderResourceByOfficer(providerCompanyId, providerResourceId, fileId) {
    return http.put(`${api}/staffing/provider/resource/${providerCompanyId}/${providerResourceId}/remove-file`, { fileId });
}



// ============== provider resource offer ===== // 


export function getProviderOffers(providerCompanyId){
    return http.get(`${api}/staffing/provider/resource/${providerCompanyId}/offers`);
}

export function getAcceptOffer(providerCompanyId,offerId){
    return http.get(`${api}/staffing/provider/resource/${providerCompanyId}/offer/accept/${offerId}`);
}

export function postRejectOffer(providerCompanyId, offerId, reason){
    return http.post(`${api}/staffing/provider/resource/${providerCompanyId}/offer/reject`, {offerId, reason});
}

export function postGeoCheckIn(providerCompanyId, shiftId, offerId, geolocation ){
    return http.post(`${api}/staffing/provider/resource/${providerCompanyId}/offer/shift/check-in/`, { shiftId, offerId, geolocation });
}

export function postGeoCheckOut(providerCompanyId, shiftId, offerId, geolocation){
    console.log('+++ postGeoCheckOut +++', providerCompanyId, shiftId, offerId, geolocation);
    return http.post(`${api}/staffing/provider/resource/${providerCompanyId}/offer/shift/check-out/`, { shiftId, offerId, geolocation } );
}

// deprecate 
export function sendPayout(providerCompanyId, offerId) {
    return http.post(`${api}/staffing/provider/resource/${providerCompanyId}/offer/payout/${offerId}`);
}



// === not ctaegotized // external 

export function getCityEvents(city, state){
    return http.get(`${api}/staffing/external/${city}/${state}/events`);
}



export async function getCopilotMultimodalStream( prompt ){
    const headers = {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        // "x-auth-texti-demo": localStorage.getItem('x-auth-texti-demo'),
        // "access-control-expose-headers": "x-auth-texti-demo"
    };

    const response = await fetch(`${api}/staffing/external/copilot/completion/multimodal`, {
        method: "post",
        headers: headers,
        body: JSON.stringify({ prompt:prompt }),
    });

    return response;
}



export function getUSBoundaries() {
    return http.get(`${api}/staffing/external/us-boundaries`);
    // return http.get(`https://api.zipscore.ai/lookup/us/geographical-boundaries/`);
}

export function getCityDemographics(cityKey, stateKey) {
    return http.get(`${api}/staffing/external/city-demographics/${cityKey}/${stateKey}`);
}