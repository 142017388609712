import React, { useEffect, useState } from 'react';
import '../../../../styles/recommendedListingItem.css'; // Ensure this path is correct or adjust as needed
import { Link } from "react-router-dom";
import toast from 'react-hot-toast';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import StarIcon from '@mui/icons-material/Star';
import Scheduler from "../../scheduler";
import { InputNumber , Badge , Button } from 'rsuite';
import { createOffer } from '../../../../../../services/staffingService';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import GradeIcon from '@mui/icons-material/Grade';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LanguageIcon from '@mui/icons-material/Language';
import CallIcon from '@mui/icons-material/Call';
import {ReactComponent as InfoCircleGreen} from '../../../../../_asset/img/info-circle_green.svg'
import { Tag, TagGroup, Whisper, Tooltip } from 'rsuite';
import {ReactComponent as TaskSquare} from '../../../../../_asset/img/task-square.svg';
import {ReactComponent as Star} from '../../../../../_asset/img/star-circle.svg';
import {ReactComponent as DollarCircle} from '../../../../../_asset/img/dollar-circle.svg';
import {ReactComponent as MakeOffer} from '../../../../../_asset/img/make_offer.svg';


function formatShiftDate(dateString, startTime, endTime) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short' };
    const formattedDate = date.toLocaleDateString('en-GB', options);
    const startHours = new Date(startTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    const endHours = new Date(endTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    
    return `${formattedDate}, ${startHours} hrs - ${endHours} hrs`;
}


// Function to format the date to "14 Aug"
function formatCompactShiftDate(dateString) {
    const options = { day: '2-digit', month: 'short' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}

// Function to format the full date and time for the tooltip
function formatFullShiftDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
}



const ListingDetails = ({ providerCompanyId, staffs, hoveredStaff, setStaffs, setHoveredStaff, ticket, job , setSelectedOfferId , setActiveTab }) => {
    const [activeStaffId, setActiveStaffId] = useState(null);

    const handleMouseEnter = (staffMember) => {
        setHoveredStaff(staffMember);
    };

    const openModal = (staffId) => {
        setActiveStaffId(staffId);
    };

    const closeModal = () => {
        setActiveStaffId(null);
    };

    const sendOffer = async (staff, job) => {
        try {
            const resp = await createOffer(providerCompanyId, staff, job);
            // const offerId = resp.data.offer._id;
            // const offerStatus = resp.data.offer.status;
            /*
            setStaffs(prevStaffs => {
                return prevStaffs.map(staff => staff._id === resp.data.offer.securityStaffId ? { ...staff, offerId: offerId } : staff);
            });
            */ 

            setStaffs(prevStaffs => {
                return prevStaffs.map(staff => {
                    if (staff._id === resp.data.offer.provider_resource_id) {
                        return {
                            ...staff,
                            offer: {
                                ...staff.offer, 
                                offerId: resp.data.offer._id,
                                status: resp.data.offer.status
                            }
                        };
                    }
                    return staff;
                });
            });
            
            setActiveStaffId(null);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error(error.response.data);
            }
        }
    };

    const handleChange = (value, staffId) => {
        setStaffs(prevStaffs =>
            prevStaffs.map(staff =>
                staff._id === staffId ? { ...staff, offerPrice: value } : staff
            )
        );
    };

    const handleTabChange = (offerId) => {
        setSelectedOfferId(offerId);
        setActiveTab('2');
    }

    return (
       <>
            <div className='recommended-staff-listing'>
            {staffs.length > 0 && (
                        <>

                            {['optimized_for_highest_margin', 'top_performers_in_area'].map(category => {
                                const staffsInCategory = staffs.filter(m => m.category === category);

                                // Only render the category if it has staff members
                                if (staffsInCategory.length === 0) {
                                    return null; 
                                }

                                return (
                                    <div key={category} style={{marginBottom: '24px'}}>

                                        {/* <div className="recommended-staff-listing-category">
                                            {category === 'optimized_for_highest_margin' && 'Recommended for highest margin optimization'}
                                            {category === 'top_performers_in_area' && 'Top performers in the area'}
                                        </div>  */}

                                        { category === 'optimized_for_highest_margin' && 
                                            <div className='alert_box'>
                                                <InfoCircleGreen />
                                                Recommended for highest margin optimization
                                            </div>
                                        }
                                        { category === 'top_performers_in_area' && 
                                            <div className='alert_box'>
                                                <InfoCircleGreen />
                                                 Top performers in the area
                                            </div>
                                        }

                                        {/* Render staff details for each category */}
                                        <div className='recommended-staff-listing-listingDetails_section'>
                                            {staffsInCategory.map(m => (
                                                <div key={m._id} className="recommended-staff-listing-view_box selected"  onClick={()=> {setHoveredStaff(m)}} /*   onMouseEnter={() => handleMouseEnter(m)} */>
                                                    <div className="title d-flex justify-content-between">
                                                        {`${m.user_id.firstName} ${m.user_id.lastName}`}
                                                        <div className="available_box listing-daily_impre">
                                                            <span>
                                                                {`${((job.hourlyPayRate - m.offerPrice) / job.hourlyPayRate * 100).toFixed(0)}% Margin`}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {/* Remaining card content */}
                                                    <div className="address mt-2">
                                                        <LocationOnIcon />
                                                        {`${m.formattedLocation} (${m.distanceMiles?.toFixed(2)} miles away)`}
                                                    </div>

                                                    <div className="daily_update">
                                                        <div className="daily_impre">
                                                            <DollarCircle />
                                                            {`20 jobs done in last 3 months`}
                                                        </div>
                                                        <div className="daily_impre daily_approx">
                                                            <Star />
                                                            {`$${m.preferences.minHourlyRate} per hour `}
                                                        </div>
                                                        <div className="daily_impre daily_approx">
                                                            <TaskSquare />
                                                            4.3
                                                        </div>
                                                    </div>

                                                    {m.assignedShifts.length > 0 && (
                                                        <div className="assigned-shifts">
                                                            <span className='label'> Offered Shifts:</span>

                                                            <TagGroup>
                                                                {m.assignedShifts.map(shift => (
                                                                    <Whisper
                                                                        key={shift._id}
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        speaker={
                                                                            <Tooltip>
                                                                                {`${formatFullShiftDate(shift.startTime)} Hrs - ${formatFullShiftDate(shift.endTime)} Hrs`}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <Tag>
                                                                            {formatCompactShiftDate(shift.date)}
                                                                        </Tag>
                                                                    </Whisper>
                                                                ))}
                                                            </TagGroup>
                                                        </div>
                                                    )}

                                                    <hr className='hr' />

                                                    {!m.offer && (
                                                        <div className="hourl_rate d-flex justify-content-between">
                                                            <div className="info">
                                                                <span className='lable'>Set Hourly Rate:</span>
                                                                <span className="selectBox" id="price-bidding">
                                                                    $
                                                                    <InputNumber
                                                                        size="xs"
                                                                        value={m.offerPrice}
                                                                        onChange={(value) => handleChange(value, m._id)}
                                                                    />
                                                                </span>
                                                            </div>
                                                            {activeStaffId === m._id && (
                                                                <Scheduler
                                                                    isOpen={true}
                                                                    onRequestClose={closeModal}
                                                                    className='scheduler-modal'
                                                                    overlayClassName='scheduler-modal-overlay'
                                                                    ariaHideApp={false}
                                                                    sendOffer={sendOffer}
                                                                    staff={m}
                                                                    job={job}
                                                                />
                                                            )}

                                                            <div onClick={() => openModal(m._id)} className='make_offer_btn'>
                                                                Make Offer <MakeOffer />
                                                            </div>
                                                        </div>
                                                    )}

                                                    {m.offer?.offerId && (
                                                        <div className="listing-request_info d-flex justify-content-end">
                                                            <div>
                                                                {/* <Link to={`/provider-company/${providerCompanyId}/jobs/${job._id}/offer/${m.offer.offerId}`} >
                                                                </Link> */}
                                                                    <div className="info" style={{ fontWeight: '500' , cursor:"pointer" }} onClick={()=>{handleTabChange(m.offer.offerId)}}>
                                                                        {m.offer.status === 'completed' && <span style={{ color: '#28a745' }}> Pending Payment <ChevronRightOutlinedIcon /> </span>}
                                                                        {m.offer.status === 'paid' && <span style={{ color: '#28a745' }}> Payment Completed  <ChevronRightOutlinedIcon /> </span>}
                                                                        {m.offer.status === 'accepted' && <span style={{ color: '#28a745' }}> Offer Accepted  <ChevronRightOutlinedIcon /> </span>}
                                                                        {m.offer.status === 'rejected' && <span style={{ color: '#dc3545' }}> Offer Rejected <ChevronRightOutlinedIcon />  </span>}
                                                                        {m.offer.status === 'expired' && <span style={{ color: '#dc3545' }}> Offer Expired <ChevronRightOutlinedIcon />  </span>}
                                                                        {m.offer.status === 'open' && <span style={{ color: '#ffb400', fontSize: '13px' }}> Pending Response <OpenInNewIcon style={{ fontSize: '14px' }} /> </span>}
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}

            </div>
        </>

    );
};

export default ListingDetails;