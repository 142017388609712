import React, { useState , useEffect } from 'react';

import VerifiedCustomers from "./verifiedCustomers"
import DashboardSidebar from '../../../common/dashboardSidebar';
import Header from '../../../common/header';
import { ReactComponent as SendUp } from '../../../_asset/img/send_up.svg';
import { ReactComponent as SearchIcon } from '../../../_asset/img/search-normal.svg';
import "./styles/customer.css"
import { Button, SelectPicker, Toggle } from 'rsuite';
import NewCustomer from './customerform';
import { useParams, useHistory } from 'react-router-dom';
import { useUser } from '../../../../context/UserContext';
import { useProviderCompany } from '../../../../context/ProviderCompanyContext';
import { getCustomerCompanies } from "../../../../services/staffingService";
import { Link } from 'react-router-dom';

const Customers = () => {

    const { userInfo, userInfoReady } = useUser();
    const { providerCompanyId } = useParams();
    const { providerCompanyData, fetchProviderCompanyData } = useProviderCompany();
    const [customerCompanies, setCustomerCompanies] = useState([]);
    const [filteredCustomerCompanies, setFilteredCustomerCompanies] = useState([]);
    const [areFiltersActive, setAreFiltersActive] = useState(false); // State to track if filters are active

    const [filters, setFilters] = useState({
        searchText: '',
        companyLegalName: null,
        facilityType: null,
        contactPersonName: null,
        email: null,
        hasSLA: false, // This will filter if the company has an SLA
    });

    // State to manage active tab
    const [activeTab, setActiveTab] = useState('verified-customers');
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchCustomerCompanies();
    }, [providerCompanyId])

    const fetchCustomerCompanies = async () => {
        const resp = await getCustomerCompanies(providerCompanyId);
        setCustomerCompanies([...resp.data.customerCompanies]);
        setFilteredCustomerCompanies([...resp.data.customerCompanies]);
    }
    

    // Function to switch tabs
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // const handleClose = () => {
    //     setOpen(false);
    // }

        // Function to handle filter changes
        const handleFilterChange = (key, value) => {
            setFilters({ ...filters, [key]: value });
        };

        const getUniqueOptions = (key) => {
            return Array.from(
                new Set(
                    customerCompanies.map((item) => {
                        const keys = key.split('.'); // Support nested keys
                        return keys.reduce((acc, curr) => acc && acc[curr], item); // Access nested properties like contact.contactPersonName
                    })
                )
            ).map((value) => ({
                label: value || 'N/A',
                value: value || 'N/A',
            }));
        };
        
    
        const applyFilters = () => {
            const filtered = customerCompanies.filter((item) => {
                const matchesSearchText =
                    filters.searchText === '' ||
                    item.companyLegalName.toLowerCase().includes(filters.searchText.toLowerCase()) ||
                    item.companyFriendlyName.toLowerCase().includes(filters.searchText.toLowerCase()) ||
                    item.contact.contactPersonName.toLowerCase().includes(filters.searchText.toLowerCase()) ||
                    item.contact.email.toLowerCase().includes(filters.searchText.toLowerCase());
    
                const matchesCompanyLegalName = !filters.companyLegalName || item.companyLegalName === filters.companyLegalName;
                const matchesFacilityType = !filters.facilityType || item.facilityType === filters.facilityType;
                const matchesContactPersonName = !filters.contactPersonName || item.contact.contactPersonName === filters.contactPersonName;
                const matchesEmail = !filters.email || item.contact.email === filters.email;
                const matchesHasSLA = !filters.hasSLA || !!item.serviceAgreementUrl; // Check if SLA exists (serviceAgreementUrl is not empty)
    
                return (
                    matchesSearchText &&
                    matchesCompanyLegalName &&
                    matchesFacilityType &&
                    matchesContactPersonName &&
                    matchesEmail &&
                    matchesHasSLA
                );
            });
    
            setFilteredCustomerCompanies(filtered);
            const activeFilters = checkIfFiltersAreActive();
            setAreFiltersActive(activeFilters); // Update state if filters are active
        };  
        
        const checkIfFiltersAreActive = () => {
            return (
                filters.searchText !== '' ||
                filters.companyLegalName !== null ||
                filters.facilityType !== null ||
                filters.contactPersonName !== null ||
                filters.email !== null ||
                filters.hasSLA !== false
            );
        };
    
        // Function to reset filters
        const resetFilters = () => {
            setFilters({
                searchText: '',
                companyLegalName: null,
                facilityType: null,
                contactPersonName: null,
                email: null,
                hasSLA: false,
            });
            setFilteredCustomerCompanies(customerCompanies); // Reset to original data
            // setSelectedFilters(false);
            setAreFiltersActive(false);
        };

    return (
      <div className="dashboard">
        <DashboardSidebar isOpen={showSideDrawer} />
        <div className="dashboard_content">
          <Header isOpen={showSideDrawer} setIsOpen={setShowSideDrawer} />

          <div className="customer_page">
            <div className="page_title">
              <div className="left_side">
                <h2>Customers</h2>
                <p>Manage customers</p>
              </div>
              <div className="right_side">


              <a href="/partner-success" target="_blank">
                <button className="count_of_customer_btn">
                  <span className="count_of_customer">84</span> Customers Around
                  You
                  <SendUp />
                </button>
              </a>

                <Link to={`/provider-company/${providerCompanyId}/crm/new`}>
                  <button
                    className="create_customer_btn"
                    //onClick={() => setOpen(true)}
                  >
                    + Create New Customer
                  </button>
                </Link>

              </div>
            </div>

            <div className="inner_content" id="scrollBar">
              <div className="filter_content">
                <div className="heading">
                  <h2 className="title">Filter</h2>
                  {/* <span className='sub_title' onClick={resetFilters} >Reset</span> */}
                </div>
                <div className="search_box">
                  <SearchIcon />
                  <input
                    type="text"
                    placeholder="Search customers"
                    value={filters.searchText} // Controlled input tied to filters state
                    onChange={(e) =>
                      handleFilterChange("searchText", e.target.value)
                    }
                  />
                </div>
                <div className="folter_items">
                  <SelectPicker
                    data={getUniqueOptions("companyLegalName")}
                    placeholder="Company Legal Name"
                    value={filters.companyLegalName} // Controlled value
                    cleanable={true} 
                    // onClean={() => handleFilterChange("companyLegalName", null)}
                    onClean={resetFilters} 
                    onChange={(value) =>
                      handleFilterChange("companyLegalName", value)
                    }
                  />
                  <SelectPicker
                    data={getUniqueOptions("facilityType")}
                    placeholder="Facility Type"
                    value={filters.facilityType} // Controlled value
                    cleanable={true} // Enable clear button in picker
                    // onClean={() => handleFilterChange("facilityType", null)} 
                    onClean={resetFilters} 
                    onChange={(value) => handleFilterChange("facilityType", value) }
                  />
                  <SelectPicker
                    data={getUniqueOptions("contact.contactPersonName")}
                    placeholder="Primary Contact Person Name"
                    value={filters.contactPersonName} // Controlled value
                    cleanable={true} // Enable clear button in picker
                    // onClean={() => handleFilterChange("contactPersonName", null) }
                    onClean={resetFilters} 
                    onChange={(value) =>
                      handleFilterChange("contactPersonName", value)
                    }
                  />
                  <SelectPicker
                    data={getUniqueOptions("contact.email")}
                    placeholder="Primary Contact Email"
                    value={filters.email} 
                    cleanable={true} 
                    // onClean={() => handleFilterChange("email", null)}
                    onClean={resetFilters} 
                    onChange={(value) => handleFilterChange("email", value)}
                  />
                </div>
                <div className="toggle_section">
                  <div className="toggle_lable">
                    <Toggle
                      checked={filters.hasSLA}
                      onChange={(value) => handleFilterChange("hasSLA", value)} // Assuming SLA is a key in your data
                    />
                    <span className="label">Has SLA</span>
                  </div>
                  {/* <div className='toggle_lable'>
                                    <Toggle />
                                    <span className='lable'>Has email address</span>
                                </div> */}
                </div>
                <div>
                  {!areFiltersActive && (
                    <button className="apply_btn" onClick={applyFilters}>
                      Apply
                    </button>
                  )}
                  {/* { selectedFilters && <span className='sub_title' onClick={resetFilters} >Reset</span> } */}
                  {areFiltersActive && (
                    <div className="heading">
                      <span className="sub_title" onClick={resetFilters}>
                        {" "}
                        Reset Filters
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="tabs_content">
                <div className="tabs">
                  {/* Tabs */}
                  <div
                    className="_tab"
                    onClick={() => handleTabClick("verified-customers")}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      borderBottom:
                        activeTab === "verified-customers"
                          ? "1px solid #404880"
                          : "none",
                      color:
                        activeTab === "verified-customers"
                          ? "#404880"
                          : "#878787",
                    }}
                  >
                    Customers
                    <span
                      style={{
                        borderRadius: "99px",
                        background: activeTab ? "#404880" : "#FFFFFF",
                        padding: "4px 8px",
                        color: activeTab ? "#FFF" : "#404880",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      {" "}
                      {filteredCustomerCompanies.length}{" "}
                    </span>
                  </div>
                  {/* <div
                                    className='_tab'
                                    onClick={() => handleTabClick('unverified-customers')}
                                    style={{
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        borderBottom: activeTab === 'unverified-customers' ? '1px solid #404880' : 'none',
                                        color: activeTab === 'unverified-customers' ? '#404880' : '#878787'
                                    }}
                                >
                                    Unverified Customers
                                    <span style={{
                                        borderRadius: "99px",
                                        background: activeTab ? "#404880" : "#FFFFFF",
                                        padding: "4px 8px",
                                        color: activeTab ? "#FFF" : "#404880",
                                        fontSize: "12px",
                                        fontWeight: 500,
                                    }}>0</span>
                                </div> */}
                </div>

                {/* Content */}
                <div className="tab_inner_content">
                  {activeTab === "verified-customers" && (
                    <VerifiedCustomers
                      customerCompanies={ customerCompanies }
                      filteredCustomerCompanies={filteredCustomerCompanies}
                      setCustomerCompanies={setCustomerCompanies}
                      setFilteredCustomerCompanies={setFilteredCustomerCompanies}
                      providerCompanyId={providerCompanyId}
                    />
                  )}
                  {activeTab === "unverified-customers" && (
                    <div className="no_data">
                      <p className="provider-company-customer-no-companies p-0 m-0">
                        display unverified customers here{" "}
                      </p>{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Modal
          open={open}
          onClose={handleClose}
          className="create_customer_modal_wrapper"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body id="scrollBar" style={{ padding: "0", margin: "0" }}>
            <NewCustomer />
          </Modal.Body>
        </Modal> */}
      </div>
    );
};

export default Customers;